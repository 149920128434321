import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import chatMenuSlice from './chat/chat.slice';
import cookieBannerReducer from './cookie-banner/cookie-banner.slice';
import externalLinkFeedbackModalReducer from './external-link-feedback-modal/external-link-feedback-modal.slice';
import intlReducer from './intl/intl.slice';
import newsFilterReducer from './news-filter/news-filter.slice';
import pushNotificationsReducer, {
  pushNotificationsPersistConfig,
} from './push-notifications/push-notifications.slice';
import searchReducer, { searchPersistConfig } from './search/search.slice';

const reducers = {
  intl: intlReducer,
  filter: newsFilterReducer,
  search: persistReducer(searchPersistConfig, searchReducer),
  pushNotifications: persistReducer(pushNotificationsPersistConfig, pushNotificationsReducer),
  cookieBanner: cookieBannerReducer,
  externalLinkFeedbackModal: externalLinkFeedbackModalReducer,
  chatMenu: chatMenuSlice,
};

const rootReducer = combineReducers(reducers);

type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
