import { useAccount } from '@azure/msal-react';
import { AxiosError } from 'axios';

import { QueryKeys, useUser } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { User } from 'types/user.types';

export const useCurrentUser = (
  config: UseCommonQueryOptions<
    User,
    AxiosError,
    User,
    ReturnType<typeof QueryKeys.users.byId>
  > = {},
) => {
  const account = useAccount();
  const userId = account?.idTokenClaims?.spencer_id?.toString();

  const { data, ...rest } = useUser(userId ?? '', {
    ...config,
    enabled: !!userId,
  });

  return {
    user: data,
    userId: data?.spencerUuid ?? '',
    serviceId: data?.serviceUuid ?? '',
    ...rest,
  };
};
