import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';

export const fetchHref = async <T>(href: string) => {
  const response: AxiosResponse<T> = await apiService.api.get(href);
  return response.data;
};

/**
 * Generic query for fetching hrefs
 */
export const useHref = <T>(
  href = '',
  config?: UseCommonQueryOptions<T, AxiosError, ReturnType<typeof QueryKeys.href>>,
) => {
  const { locale } = useSelectedLocale();

  const query = useQuery<T, AxiosError>({
    queryKey: QueryKeys.href(href, locale),
    queryFn: () => fetchHref<T>(href),
    staleTime: Duration.FIFTEEN_MIN,
    enabled: !!href,
    ...config,
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isError: query.isError,
    error: query.error,
    refetch: query.refetch,
  };
};
