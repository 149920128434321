export type Href = {
  href: string;
};

export type Video = {
  hlsUrl: string;
  thumbnailLarge: string;
};

export type Meta = {
  page: number;
  pages: number;
  perPage: number;
  total: number;
};

export type Collection<T> = {
  data?: T;
  meta?: Meta;
  links?: Links;
};

export type Links = {
  prev?: string;
  self: string;
  next?: string;
};

export type Pagination = {
  p?: number;
  limit?: number;
};

export type SiteParams = {
  location_tracking?: number;
} & Pagination;

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum RouteType {
  User = 'user',
  UserServiceId = 'userServiceId',
  NewsItem = 'newsItem',
  NewsItemPreview = 'newsItemPreview',
  UserBookmark = 'userBookmark',
  Topic = 'topic',
  DocumentLibrary = 'documentLibrary',
  Survey = 'survey',
  Site = 'site',
}

export type WorkeloMagicLink = {
  magicLink: string;
};
