import { captureException } from '@sentry/capacitor';

import { isNative } from 'utils/capacitor.utils';

import { fetchHref } from 'queries';
import { StorageMedia } from 'types/media.types';

export const downloadStorageFile = async (hrefId: string, fileName: string) => {
  try {
    const fileHref = await fetchHref<StorageMedia>(hrefId);
    if (isNative) {
      window.location.href = fileHref.signedUrl;
    } else {
      const response = await fetch(fileHref.signedUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.target = '_blank';
      link.click();
      setTimeout(() => {
        link.remove();
      }, 200);
    }
  } catch (error) {
    captureException(error, { level: 'warning' });
  }
};
