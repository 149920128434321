import { useQuery } from '@tanstack/react-query';

import { fetchAllNextQueries } from 'utils/api.utils';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { Link, LinkParams } from 'types/links.types';

const fetchLinks = async (params: LinkParams) =>
  fetchAllNextQueries(
    async (nextParams) => {
      const response = await apiService.getLinks({ ...params, ...nextParams });
      return response?.data;
    },
    { groupBy: 'data' },
  );

export const useNavLinks = (params: LinkParams) => {
  const { locale } = useSelectedLocale();

  const query = useQuery<Array<Link>>({
    queryKey: QueryKeys.links.navigation(locale, params),
    queryFn: async () => {
      const data = await fetchLinks(params);
      const resolvedHrefs = await Promise.all(data?.map((href) => apiService.api(href.href)) ?? []);
      return resolvedHrefs.map((data) => data.data);
    },
    staleTime: Duration.FOREVER,
  });

  return {
    data: query.data ?? [],
    isLoading: query.isLoading,
  };
};
