import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { IconNames } from 'sprite/types';

import { notEmpty } from 'utils/typescript.utils';

import { Routes } from 'constants/routes.constants';
import { useEnabledModules, useInfiniteLinks, useMe, useTenantConfig } from 'queries';
import { useNavLinks } from 'queries/links/useNavLinks';
import { useWorkeloMagicLink } from 'queries/workelo';
import { Href } from 'types/api.types';
import { FeatureFlags } from 'types/features.types';
import { Link, LinkType } from 'types/links.types';

import { iframeModules, Modules, webModules } from './config';

type NavItem = {
  name?: string;
  label?: string;
  to: string;
  target?: string;
  startIcon?: IconNames;
  startImage?: Href;
  showInMobileMenu?: boolean;
  type?: LinkType.FileLink | LinkType.Form | LinkType.WebLink;
};

export const useEnabledIframeModules = () => {
  const { data: enabledModules, isLoading: isLoadingEnabledModules } = useEnabledModules();
  const { data: meData, isLoading: isLoadingMe } = useMe();

  const enabledIframeModules = useMemo(
    () =>
      iframeModules.filter(
        (iframeModule) =>
          enabledModules?.includes(iframeModule.id) &&
          !meData?.restrictedModules?.includes(iframeModule.id),
      ),
    [enabledModules, meData],
  );

  return { enabledIframeModules, isLoading: isLoadingEnabledModules || isLoadingMe };
};

const useEnabledWebModules = () => {
  const { data: enabledModules } = useEnabledModules();
  const { data: meData } = useMe();
  const { data: tenantConfig } = useTenantConfig();

  const enabledWebModules = useMemo(
    () =>
      webModules.filter((webModule) =>
        webModule.ids.some((id) => {
          // Step 1: Check if module is enabled
          const isEnabled = enabledModules?.includes(id);
          // Step 2: Check if module is not restricted for the user
          const isNotRestricted = !meData?.restrictedModules?.includes(id);
          // Step 3: Check if the corresponding feature is enabled in tenantConfig
          const featureKey = Object.keys(tenantConfig?.configuration?.features || {}).find(
            (key) => key.toLowerCase() === id.toLowerCase(),
          ) as FeatureFlags | undefined;
          const isFeatureEnabled = featureKey
            ? (tenantConfig?.configuration?.features as Record<FeatureFlags, boolean>)?.[
                featureKey
              ] !== false
            : true;

          return isEnabled && isNotRestricted && isFeatureEnabled;
        }),
      ),
    [enabledModules, meData, tenantConfig],
  );

  return enabledWebModules;
};

// INFO: Hook is only for the Workelo module 'module_workelo' a link should be shown in the menu called Workelo
const useWorkeloNavItem = () => {
  const { data: enabledModules } = useEnabledModules();
  const workeLoEnabled = !!enabledModules?.includes(Modules.Workelo);
  const { data: workeloMagicLink } = useWorkeloMagicLink({
    enabled: workeLoEnabled,
  });

  if (!workeLoEnabled) return [];
  if (!workeloMagicLink?.magicLink) return [];

  return [
    {
      label: 'module_workelo_overview_title',
      to: workeloMagicLink.magicLink,
      target: '_blank',
      startIcon: 'IcoWorkelo' as IconNames,
    },
  ];
};

const mapLinkToNavItemTo = (link: Link) => {
  if (link.type === LinkType.Form)
    return generatePath(Routes.FormsDetail, { itemId: String(link.id) });
  if (link.type === LinkType.WebLink) {
    if (link.openExternal) return link.webUrl;
    return generatePath(Routes.IframeDetail, { id: String(link.id) });
  }
  if (link.type === LinkType.FileLink) return link.storageFile?.href;
};

export const useNavItems = () => {
  const { enabledIframeModules } = useEnabledIframeModules();
  const workeloNavItem = useWorkeloNavItem();
  const enabledWebModules = useEnabledWebModules();
  const { data: featuredNavLinks } = useNavLinks({
    featured: true,
    type: [LinkType.WebLink, LinkType.Form, LinkType.FileLink],
  });

  const { data: links, isLoading: isLoadingLinks } = useInfiniteLinks({
    featured: false,
    limit: 1,
    type: LinkType.WebLink,
  });
  const { data: forms, isLoading: isLoadingForms } = useInfiniteLinks({
    featured: false,
    limit: 1,
    type: LinkType.Form,
  });

  const mappedFeaturedNavLinks = useMemo(
    () =>
      featuredNavLinks
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((link) => ({
          name: link.name,
          target: link.openExternal ? '_blank' : undefined,
          startImage: link.image,
          type: link.type as NavItem['type'],
          to: mapLinkToNavItemTo(link) ?? '',
        })),
    [featuredNavLinks],
  );

  const emptyLinks = !links?.length && !isLoadingLinks;
  const emptyForms = !forms?.length && !isLoadingForms;

  const coreModuleNav = useMemo(
    () =>
      enabledWebModules.filter((webModule) => {
        const linkModule = webModule.ids.find((id) => id === Modules.LinkLibrary);
        const formsModule = webModule.ids.find((id) => id === Modules.Forms);
        if (emptyLinks && linkModule) return;
        if (emptyForms && formsModule) return;
        return webModule;
      }),
    [emptyForms, emptyLinks, enabledWebModules],
  );

  const iframeModulesNav = useMemo(
    () =>
      enabledIframeModules.map((iframeModule) => ({
        label: iframeModule.name,
        to: iframeModule.path,
        startIcon: iframeModule.iconName,
      })),
    [enabledIframeModules],
  );

  const navItems = useMemo<Array<NavItem>>(() => {
    return [
      // core modules
      ...coreModuleNav,
      // enabled iframe modules
      ...iframeModulesNav,
      // Workelo
      ...workeloNavItem,
      ...mappedFeaturedNavLinks,
    ].filter(notEmpty);
  }, [coreModuleNav, iframeModulesNav, workeloNavItem, mappedFeaturedNavLinks]);

  return navItems;
};
