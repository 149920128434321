import {
  CacheLookupPolicy,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Browser } from '@capacitor/browser';
import { StatusBar } from '@capacitor/status-bar';
import { flush } from '@sentry/core';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { isAndroid, isNative } from 'utils/capacitor.utils';
import { captureException } from 'utils/sentry.utils';

import { useLogOut } from 'queries';
import { useSelectedLocale } from 'services/i18n';

import { Splash } from 'components/@splash';

import IdentityRetry from './IdentityRetry';
import { getB2cLocale } from './utils';

const MSAL_DOMAIN_HINT_KEY = 'msal.domain.hint';
const MSAL_STATUS_KEY = 'msal.interaction.status';

export let getAccessToken: () => Promise<string | null> = async () => null;

interface Props {
  children: ReactNode;
  scopes: Array<string>;
}

const IdentityConsumer = ({ children, scopes }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance: msalInstance, inProgress } = useMsal();
  const isAuthenticationInProgress = useRef(false);
  const ssoProvider = localStorage.getItem(MSAL_DOMAIN_HINT_KEY) || undefined;
  const [hasToken, setHasToken] = useState(false);
  const { locale } = useSelectedLocale();
  const { logOut } = useLogOut();

  const acquireTokenRedirect = useCallback(async () => {
    if (isNative) {
      await msalInstance.acquireTokenRedirect({
        scopes,
        prompt: 'login',
        domainHint: ssoProvider,
        extraQueryParameters: { ui_locales: getB2cLocale(locale) },
        onRedirectNavigate: (url) => {
          Browser.open({ url });
          return false;
        },
      });
    } else {
      await msalInstance.acquireTokenRedirect({
        scopes,
        domainHint: ssoProvider,
        extraQueryParameters: { ui_locales: getB2cLocale(locale) },
      });
    }
  }, [locale, msalInstance, scopes, ssoProvider]);

  const handleAuthentication = useCallback(async () => {
    try {
      await msalInstance.handleRedirectPromise();
    } catch (error) {
      captureException(error);
      //Flush does not exist yet in @sentry/capacitor
      if (!isNative) await flush(2000);

      await logOut();
      throw error;
    }

    if (
      (inProgress === InteractionStatus.None || inProgress === InteractionStatus.Login) &&
      !isAuthenticationInProgress.current
    ) {
      if (!isAuthenticated) {
        // Don't redirect when interaction is in progress
        if (!!accounts.length || isAuthenticationInProgress.current) return;

        isAuthenticationInProgress.current = true;

        await acquireTokenRedirect();
      }

      if (isAuthenticated && !!accounts.length) {
        getAccessToken = async () => {
          // Get & return the token, if it doesn't work, redirect to login page
          try {
            //Token is not in cache or expired, get it from the server
            const { accessToken, account } = await msalInstance.acquireTokenSilent({
              scopes,
              prompt: 'login',
              account: accounts[0],
              cacheLookupPolicy: CacheLookupPolicy.Default,
              refreshTokenExpirationOffsetSeconds: 7200,
            });

            // Always set this ourself since we need to know it incase we need to
            // login again
            const ssoIdpClaim = account.idTokenClaims?.idp;
            if (ssoIdpClaim) {
              localStorage.setItem(MSAL_DOMAIN_HINT_KEY, ssoIdpClaim);
            }

            return accessToken;
          } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
              await acquireTokenRedirect();
              return null;
            }

            throw error;
          }
        };

        const token = await getAccessToken();

        setHasToken(!!token);
        msalInstance.setActiveAccount(accounts[0]);
      }

      isAuthenticationInProgress.current = false;
    }
  }, [inProgress, msalInstance, logOut, isAuthenticated, accounts, acquireTokenRedirect, scopes]);

  const clearCache = useCallback(async () => {
    localStorage.removeItem(MSAL_STATUS_KEY);
    await msalInstance.logoutRedirect({ onRedirectNavigate: () => false });
  }, [msalInstance]);

  useEffect(() => {
    if (!isNative) handleAuthentication();
  }, [handleAuthentication]);

  useEffect(() => {
    if (isAuthenticated && isNative) {
      handleAuthentication();
    }
  }, [handleAuthentication, isAuthenticated]);

  useEffect(() => {
    if (isAndroid) {
      const setStatusBarBackground = async () => {
        await StatusBar.setOverlaysWebView({ overlay: true }); // Hide native status bar
      };
      setStatusBarBackground();
    }
  }, []);

  if (!hasToken)
    return (
      <>
        {isNative &&
        (inProgress === InteractionStatus.Login ||
          inProgress === InteractionStatus.Logout ||
          inProgress === InteractionStatus.None) &&
        !isAuthenticated ? (
          <IdentityRetry
            onHandleRetry={() => {
              if (localStorage.getItem(MSAL_STATUS_KEY)) {
                clearCache();
              }

              handleAuthentication();
            }}
          />
        ) : (
          <Splash isLoading />
        )}
      </>
    );

  return <>{children}</>;
};

export default IdentityConsumer;
