/**
 * This is the magic that allows to have a gooey background filter
 * We only need to include this once in the code base, make it available in the dom
 * And it can be used for any gooey typography
 * @see GooeyTypography component
 * @see https://codepen.io/ines/pen/NXbmRO?editors=1100
 * @see https://codepen.io/5h4dy_s/pen/RwGjybx
 */
const GooeySvgFilter = () => (
  <svg
    style={{ visibility: 'hidden', position: 'absolute' }}
    width="0"
    height="0"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <defs>
      <filter id="gooey-filter">
        <feGaussianBlur in="SourceGraphic" stdDeviation="7.5" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0
                0 1 0 0 0
                0 0 1 0 0
                0 0 0 18 -9"
          result="colormatrix"
        />
        <feComposite in="SourceGraphic" in2="colormatrix" operator="atop" />
      </filter>
    </defs>
  </svg>
);

export default GooeySvgFilter;
