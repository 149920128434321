import App from 'App';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { isNative } from 'utils/capacitor.utils';
import { runInDev, runInProd } from 'utils/env.utils';
import registerErrorOverlay from 'utils/vite-error-overlay';

import { initNativeSentry } from 'services/sentry/native-sentry';
import { initWebSentry } from 'services/sentry/web-sentry';

import { RootBoundary } from 'components/@boundaries';
import GooeySvgFilter from 'components/@common/GooeyTypography/GooeySvgFilter';

// We lazy load this chunk because it's only needed for native platforms (reduces bundle size for web)
const IdentityDeepLinkProvider = lazy(() => import('./services/identity-deep-link/provider'));
const CapacitorApp = lazy(() => import('./components/@capacitor/CapacitorApp/CapacitorApp'));

runInProd(() => {
  if (isNative) {
    initNativeSentry();
  } else {
    initWebSentry();
  }
});

runInDev(() => {
  registerErrorOverlay();
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <RootBoundary>
      {isNative ? (
        <Suspense>
          <IdentityDeepLinkProvider>
            <CapacitorApp />
          </IdentityDeepLinkProvider>
        </Suspense>
      ) : (
        <App />
      )}
      <GooeySvgFilter />
    </RootBoundary>
  </StrictMode>,
);
