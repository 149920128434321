import { Pagination, SiteParams } from 'types/api.types';
import { DocumentFavouriteParams, DocumentLibraryParams } from 'types/documents.types';
import { LinkParams } from 'types/links.types';
import { ReactionKind } from 'types/news.types';
import { SearchParams } from 'types/search.types';
import { FeedToken } from 'types/token.types';
import { UserProfilePictureGetParams } from 'types/user.types';

enum Keys {
  Image = 'image',
  User = 'user',
  Site = 'site',
  Zones = 'Zones',
  Configuration = 'configuration',
  EnabledModules = 'enabled-modules',
  Iframe = 'iframe',
  Me = 'me',
  News = 'news',
  Feed = 'feed',
  Measurements = 'measurements',
  Featured = 'featured',
  Topics = 'topics',
  Emergency = 'emergency',
  Bookmarks = 'bookmarks',
  Bookmarked = 'bookmarked',
  Colleagues = 'colleagues',
  Team = 'team',
  Href = 'href',
  Links = 'links',
  Navigation = 'navigation',
  Notifications = 'notifications',
  Search = 'search',
  Survey = 'survey',
  Answers = 'answers',
  AllAnswers = 'allAnswers',
  Reactions = 'reactions',
  Media = 'media',
  Documents = 'documents',
  Favourites = 'favourites',
  FavouriteIds = 'favouriteIds',
  Tenant = 'tenant',
  Interval = 'interval',
  Application = 'applications',
  Locales = 'locales',
  Chat = 'chat',
  ChatUsers = 'chat-users',
  Workelo = 'workelo',
}

export const QueryKeys = {
  tenants: {
    initial: () => [Keys.Tenant],
    interval: () => [Keys.Tenant, Keys.Interval],
  },
  contacts: {
    search: (params: SearchParams) => [Keys.User, Keys.Search, params],
  },
  survey: {
    answers: (locale: string, publicationId?: number) => [
      Keys.Survey,
      Keys.Answers,
      locale,
      publicationId,
    ],
    allAnswers: (locale: string, publicationId?: number) => [
      Keys.Survey,
      Keys.AllAnswers,
      locale,
      publicationId,
    ],
  },
  reactions: {
    all: (activityId: string, locale: string, reactionKind?: ReactionKind, limit?: number) =>
      [Keys.Reactions, activityId, locale, reactionKind, limit].filter(Boolean),
  },
  users: {
    all: (params?: Pagination) => [Keys.User, params],
    byId: (...rest: Array<unknown>) => [Keys.User, ...rest],
    colleagues: (userId: string) => [Keys.User, Keys.Colleagues, userId],
    team: (userId: string) => [Keys.User, Keys.Team, userId],
    bookmarks: (userId: string) => [Keys.User, Keys.Bookmarks, userId],
    bookmarked: (userId: string, publication: number) => [
      Keys.User,
      Keys.Bookmarked,
      userId,
      publication,
    ],
  },
  sites: {
    all: (params: SiteParams) => [Keys.Site, params],
    zones: (siteId: string) => [Keys.Site, Keys.Zones, siteId],
  },
  configuration: {
    enabledModules: () => [Keys.Configuration, Keys.EnabledModules],
    me: () => [Keys.Configuration, Keys.Me],
    locales: () => [Keys.Configuration, Keys.Locales],
  },
  iframe: {
    byEndpoint: (endpoint: string) => [Keys.Iframe, endpoint],
  },
  notifications: {
    all: (userId: string, applicationName: string, locale: string) => [
      Keys.Notifications,
      userId,
      applicationName,
      locale,
    ],
    application: (applicationName: string, endpointArn: string) => [
      applicationName,
      endpointArn,
      Keys.Notifications,
      Keys.Application,
    ],
    topics: (locale: string) => [Keys.Notifications, Keys.Topics, locale],
  },
  news: {
    all: (token: FeedToken, locale: string) => [Keys.News, locale, token],
    activity: (id: string) => [Keys.News, id],
    featured: (token: FeedToken, locale: string) => [Keys.Featured, locale, token],
  },
  topics: {
    all: (locale: string, userId: string) => [Keys.Topics, locale, userId],
    allUserTopics: (userId: string) => [Keys.Topics, userId], // to update subscriptions across all languages
    user: (userId: string, locale?: string) => [Keys.Topics, userId, locale],
    byId: (href: string, locale: string) => [Keys.News, locale, href],
  },
  chat: {
    users: (search: string, locale: string) => [Keys.ChatUsers, search, locale],
  },
  token: {
    feed: () => [Keys.Feed],
    chat: () => [Keys.Chat],
    measurements: () => [Keys.Measurements],
  },
  emergency: {
    all: (token: FeedToken, locale: string) => [Keys.Emergency, locale, token],
  },
  links: {
    all: (locale: string, params?: LinkParams) => [Keys.Links, locale, params],
    navigation: (locale: string, params?: LinkParams) => [
      Keys.Links,
      Keys.Navigation,
      locale,
      params,
    ],
    byId: (id: number) => [Keys.Links, id],
  },
  search: {
    all: (params: SearchParams, locale?: string) => [Keys.Search, params, locale],
  },
  images: {
    imageUrl: (href?: string, params?: UserProfilePictureGetParams) => [Keys.Image, href, params],
  },
  media: {
    byId: (mediaId: string) => [Keys.Media, mediaId],
  },
  documents: {
    all: (userId: string, locale: string, params?: DocumentLibraryParams) => [
      Keys.Documents,
      userId,
      locale,
      params,
    ],
    byId: (nodeId: string, locale: string) => [Keys.Documents, nodeId, locale],
    folder: (folderId: string, locale: string, params?: DocumentLibraryParams) => [
      Keys.Documents,
      folderId,
      locale,
      params,
    ],
    favouriteIds: (userId: string, locale: string) => [
      Keys.Documents,
      Keys.FavouriteIds,
      userId,
      locale,
    ],
    favourites: (userId: string, locale: string, params?: DocumentFavouriteParams) =>
      [Keys.Documents, Keys.Favourites, userId, locale, params].filter(Boolean),
  },
  href: (href: string, locale?: string) => [Keys.Href, href, locale].filter(Boolean),
  workelo: {
    magicLink: () => [Keys.Workelo],
  },
};
