import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { hapticsImpact } from 'utils/capacitor.utils';

import { LinkType } from 'types/links.types';

import MenuItemComponent from './components/MenuItem';
import { downloadStorageFile } from './utils';

interface Props {
  label: string;
  to: string;
  target?: HTMLAttributeAnchorTarget;
  startIcon?: React.ReactNode;
  action?: (isActive: boolean) => ReactNode;
  removeBorderRadius?: boolean;
  type?: LinkType;
}

const NavItem = ({
  label,
  startIcon,
  to,
  action,
  target = '_self',
  removeBorderRadius,
  type,
}: Props) => {
  const handleFileDownload = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await downloadStorageFile(to, label);
  };

  const handleRegularClick = async () => {
    await hapticsImpact('light');
  };

  return (
    <NavLink
      to={to}
      title={label}
      target={target}
      onClick={type === LinkType.FileLink ? handleFileDownload : handleRegularClick}
      style={type === LinkType.FileLink ? { cursor: 'pointer' } : undefined}
    >
      {({ isActive }) => (
        <MenuItemComponent
          label={label}
          startIcon={startIcon}
          action={action ? action(isActive) : undefined}
          isActive={type === LinkType.FileLink ? false : isActive}
          removeBorderRadius={removeBorderRadius}
        />
      )}
    </NavLink>
  );
};

export default NavItem;
